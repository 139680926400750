<template>
  <el-aside :width="$store.getters.MenuType.asideWidth">
    <div class="page-nav" @mouseleave="onLeaveTd">
      <div class="left-nav">
        <div class="top-nav__logo" v-if="logo">
          <div class="top-nav__content">
            <span class="top-nav__content--title"> <i class="iconfont icon-liujisuan" />天枢 </span>
            <br />
            <span class="top-nav__content--desc">TIANSHU</span>
          </div>
        </div>
        <!-- {{$store.getters.menuList.length}} -->
        <div class="left-nav__list">
            <!-- v-for="(item, key) in ParentMenuList" -->
            <!-- v-for="(item, key) in $store.getters.menuList" -->
          <div
            class="left-nav__item"
            :class="{ 'v-active': $store.getters.MenuType.ParentMenuUrl === item.id }"
            :key="key"
            v-for="(item, key) in $store.getters.menuList"
            v-waves
            @mouseenter="onEnterTd(item)"
            @click="ClickMenu(item)"
          >
            <!-- <i :class="`iconfont ${item.icon}`" /> -->
            <i :class="`${item.icon}`" />
            <span class="left-nav__item--name">{{ item.name }}</span>
          </div>
        </div>
      </div>
      <!-- animated fadeInLeft -->
      <div class="rightMenuType"  v-show="rightMenuType">
        <div class="right-nav ">
        <div class="right-nav-title">
          {{$store.getters.MenuType.SubmenuTitle}}
        </div>
        <el-menu
          :default-active="$store.getters.MenuType.SubmenuUrl"
          class="el-menu-vertical-demo"
          @select="select"
        >
        <div v-for="(item, index) in SubmenuList" :key="index">

          <el-submenu v-if="item.children && item.children.length" :index="item.id + ''">
            <template slot="title">
               <i :class="item.icon"></i>
                <span slot="title">{{item.name}}</span>
            </template>
              <!-- <el-menu-item :index="item.url">选项1</el-menu-item> -->
              <el-menu-item  v-for="(ite, ind) in item.children" :key="ind" :index="ite.path"
              :class="{ 'activ-class': $store.getters.MenuType.SubmenuUrl === ite.path }">
                <i :class="ite.icon"></i>
                <span slot="title">{{ite.name}}</span>
              </el-menu-item>
          </el-submenu>
          <el-menu-item v-else :index="item.path" :class="{ 'activ-class': $store.getters.MenuType.SubmenuUrl === item.path }">
            <i :class="item.icon"></i>
            <span slot="title">{{item.name}}</span>
          </el-menu-item>
        </div>
        </el-menu>
      </div>
      </div>
      <!-- <div class="right-nav " v-if="$store.getters.MenuType.rightMenuType"> -->
      <div class="right-nav "
        v-if="$store.getters.SubmenuList && $store.getters.SubmenuList.length && menuType.rightMenuType">
        <div class="right-nav-title">
           {{$store.getters.MenuType.SubmenuTitle}}
        </div>
        <el-menu
          :default-active="$store.getters.MenuType.SubmenuUrl"
          class="el-menu-vertical-demo"
          @select="select"
        >
        <div v-for="(item, index) in $store.getters.SubmenuList" :key="index">

          <el-submenu v-if="item.children && item.children.length" :index="item.path">
            <template slot="title">
               <i :class="item.icon"></i>
                <span slot="title">{{item.name}}</span>
            </template>
              <el-menu-item  v-for="(ite, ind) in item.children" :key="ind" :index="ite.path"
              :class="{ 'activ-class': $store.getters.MenuType.SubmenuUrl === ite.path }">
                <i :class="ite.icon"></i>
                <span slot="title">{{ite.name}}</span>
              </el-menu-item>
          </el-submenu>
          <el-menu-item v-else :index="item.path" :class="{ 'activ-class': $store.getters.MenuType.SubmenuUrl === item.path }">
            <i :class="item.icon"></i>
            <span slot="title">{{item.name}}</span>
          </el-menu-item>
        </div>
        </el-menu>
      </div>
    </div>
  </el-aside>
</template>

<script>
import './index.scss';
import router from '@/router';
import {
  // _setSessionStore,
  _getSessionStore
} from '@/utils/storage';

export default {
  name: 'index',
  props: {
    logo: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {
      SubmenuListTEXT: [],
      rightMenuType: false,
      menuType: {
        asideWidth: '50px',
        rightMenuType: false,
        ParentMenuUrl: 2019, // 一级菜单目录
        SubmenuUrl: '/', // 子菜单目录
        SubmenuTitle: '' // 子菜单title
      },
      SubmenuList: [],
      ParentMenuList: [],
      currentPath: '/'
    };
  },
  created () {
    // const that = this;
    // setTimeout(() => {
    //   that.ParentMenuList = _getSessionStore('menus', 'JSONStr');
    // }, 500);
    const navSelf = _getSessionStore('navSelf', 'JSONStr');
    const SubmenuList = _getSessionStore('SubmenuList', 'JSONStr');
    // if (this.$route.path !== '/dashboard') {
    // }
    if (navSelf) {
      this.menuType = navSelf;
      // this.toPage(navSelf.SubmenuUrl);
      if (this.$route.path !== navSelf.SubmenuUrl) {
        router.push(navSelf.SubmenuUrl);
      }
      this.$store.dispatch('config/setMenuType', this.menuType);
    } else {
      this.$store.dispatch('config/setMenuType', this.menuType);
    }
    if (SubmenuList) {
      this.SubmenuList = SubmenuList;
      this.$store.dispatch('config/setSubmenuList', this.SubmenuList);
    }
    // this.currentPath = this.$route.path === '/dashboard' ? '/' : this.$route.path;
    // this.$route.path
  },
  methods: {
    // 鼠标进入的事件。
    onEnterTd (e) {
      // console.log('鼠标进入的事件。', e, e.children, e.children.length);
      this.menuType.ParentMenuUrl = e.id;
      if (e.children && e.children.length) {
        // this.menuType.asideWidth = '210px';
        this.menuType.SubmenuTitle = e.remark || '';
        this.rightMenuType = true;
        this.menuType.rightMenuType = false;
        this.SubmenuListTEXT = e.children;
        this.SubmenuList = e.children;
      } else {
        this.rightMenuType = false;
        this.menuType.rightMenuType = true;
      }
    },
    // 鼠标离开的事件。
    onLeaveTd () {
      console.log('鼠标离开的事件');
      const navSelf = _getSessionStore('navSelf', 'JSONStr');
      const SubmenuList = _getSessionStore('SubmenuList', 'JSONStr');
      this.rightMenuType = false;
      this.menuType.rightMenuType = false;
      if (navSelf) {
        this.menuType = navSelf;
      }
      if (SubmenuList && SubmenuList.length) {
        this.menuType.rightMenuType = true;
        this.SubmenuList = SubmenuList;
      }
    },
    ClickMenu (e) {
      console.log('点击父菜单', e);
      this.menuType.ParentMenuUrl = e.id;
      if (e.children && e.children.length) {
        this.menuType.asideWidth = '210px';
        this.menuType.SubmenuTitle = e.remark || '';
        this.menuType.rightMenuType = true;
        // console.log('--------------------------1---------------------', this.menuType.rightMenuType);
        // console.log('--------------------------1---------------------', this.menuType);
        this.SubmenuList = e.children;
        if (e.children[0].children && e.children[0].children.length) {
          this.toPage(e.children[0].children[0].path);
        } else {
          this.toPage(e.children[0].path);
        }
      } else {
        // this.SubmenuList = [];
        this.menuType.rightMenuType = false;
        this.menuType.asideWidth = '50px';
        this.toPage(e.path);
      }
    },
    select (e) {
      console.log('select', this.SubmenuListTEXT);
      // this.SubmenuUrl = e;
      this.SubmenuList = this.SubmenuListTEXT;
      this.menuType.asideWidth = '210px';
      this.menuType.rightMenuType = true;
      this.toPage(e);
    },
    toPage (url) {
      if (this.$route.path === url) {
        return true;
      }
      this.menuType.SubmenuUrl = url;
      console.log('--------------------------2---------------------', this.menuType);
      this.$store.dispatch('config/setSubmenuList', this.SubmenuList);
      this.$store.dispatch('config/setMenuType', this.menuType);
      console.log(this.$store.getters.menuType, '$store.getters.menuType');
      // _setSessionStore(
      //   'navSelf',
      //   this.menuType,
      //   'JSONStr'
      // );
      // _setSessionStore(
      //   'SubmenuList',
      //   this.SubmenuList,
      //   'JSONStr'
      // );
      router.push(url);
    },
    jump (item) {
      if (this.currentPath === item.path) {
        return true;
      }
      this.currentPath = item.path;
      router.push(item.path);
    }
  }
};
</script>

<style scoped>
.is-active{
  color: #303133;
}
.activ-class{
  background-color: #EBEEF5;
  color: #409EFF;
}

</style>
