<template>
  <div class="top-nav">
<!--    <div class="top-nav__search">-->
<!--        <input class="top-nav__search&#45;&#45;input" type="text" placeholder="请输入要搜索的内容" />-->
<!--    </div>-->
    <div>
       <router-link to="/" class="top-nav__item">首页</router-link>
    </div>
  </div>
</template>

<script>

export default {
  name: 'index',
  data () {
    return {
      activeIndex: '',
      list: [
      ]
    };
  },
  methods: {
    logout () {
      // logout
      this.$confirm('是否退出登录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // removeToken();
        console.log(user);
        // console.log('@@@@@!!!@@@@', this.$route.fullPath);
        user.logout().then(res => {
          console.log(res);
          this.$message({
            type: 'success',
            message: '退出成功!'
          });
          removeToken();
          sessionStorage.clear();
          this.$router.push('/login');
        });
      });
      // await this.$store.dispatch('user/logout')
      // this.$router.push(`/login?redirect=${this.$route.fullPath}`)
    },
    handleSelect (e) {
      console.log('点击菜单', e);
    }
  }
};
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
