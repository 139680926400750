import './index.scss';

export default {
  name: 'loading',
  props: {

  },
  data () {
    return {};
  }
};
