import Layout from '@/layout/index';

export default [
  {
    path: '/',
    name: 'Home',
    component: Layout,
    redirect: '/dashboard',
    children: [
      {
        path: '/dashboard',
        component: () => import('@/views/dashboard/index'),
        name: 'Dashboard',
        meta: { title: '首页', icon: 'dashboard', affix: true }
      },
      {
        path: '/data/panel',
        name: 'data-panel',
        component: () => import(/* webpackChunkName: "resources" */ '@/views/system/data/panel.vue'),
        meta: { title: '数据面板', affix: true }
      },
      {
        path: '/resources',
        name: 'resources',
        component: () => import(/* webpackChunkName: "resources" */ '@/views/system/resources/index.vue'),
        meta: { title: '资源管理', affix: true }
      },
      {
        path: '/sys/menu',
        name: 'sys-menu',
        component: () => import(/* webpackChunkName: "sys-menu" */ '@/views/system/menu/index.vue'),
        meta: { title: '菜单管理', affix: true }
      },
      {
        path: '/sys/accessory',
        name: 'sys-accessory',
        component: () => import(/* webpackChunkName: "sys-role" */ '@/views/system/accessory/index.vue')
      },
      {
        path: '/sys/role',
        name: 'sys-role',
        component: () => import(/* webpackChunkName: "sys-role" */ '@/views/system/role/index.vue'),
        meta: { title: '角色管理', affix: true }
      },
      {
        path: '/sys/log/logininfor',
        name: 'sys-logininfor',
        component: () => import(/* webpackChunkName: "about" */ '@/views/system/log/logininfor/index.vue')
      },
      {
        path: '/sys/log/operlog',
        name: 'sys-role',
        component: () => import(/* webpackChunkName: "about" */ '@/views/system/log/operlog/index.vue')
      },
      {
        path: '/manage/message/sms',
        name: 'sms',
        component: () => import(/* webpackChunkName: "about" */ '@/views/manage/message/sms.vue')
      },
      {
        path: '/sys/account',
        name: 'sys-account',
        component: () => import(/* webpackChunkName: "sys-account" */ '@/views/system/account/index.vue'),
        meta: { title: '账号列表', affix: true }
      },
      {
        path: '/sys/setting',
        name: 'sys-setting',
        component: () => import(/* webpackChunkName: "sys-setting" */ '@/views/system/setting/index.vue'),
        meta: { title: '系统设置', affix: true }
      },
      {
        path: '/dictionary',
        name: 'dictionary',
        component: () => import(
          /* webpackChunkName: "sys-dict-type" */ '@/views/system/dictionary/type/index.vue'
        ),
        meta: { title: '数据字典', affix: true }
      },
      {
        path: '/dictionary/data/:id',
        name: 'dictionaryData',
        component: () => import(/* webpackChunkName: "sys-dict-data" */ '@/views/system/dictionary/data/index.vue')
      },
      {
        path: '/trading/list',
        name: 'trading-list',
        component: () => import(/* webpackChunkName: "trading-list" */ '@/views/manage/trading/index.vue'),
        meta: { title: '报单管理', affix: true }
      },
      {
        path: '/banner/list',
        name: 'banner-list',
        component: () => import(/* webpackChunkName: "banner-list" */ '@/views/manage/banner/index.vue'),
        meta: { title: '轮播图管理', affix: true }
      },
      {
        path: '/poster/list',
        name: 'poster-list',
        component: () => import(/* webpackChunkName: "poster-list" */ '@/views/manage/poster/index.vue'),
        meta: { title: '海报模板', affix: true }
      },
      {
        path: '/product/list',
        name: 'product-list',
        component: () => import(/* webpackChunkName: "product-list" */ '@/views/manage/product/index.vue'),
        meta: { title: '产品列表', affix: true }
      },
      {
        path: '/rebate/list',
        name: 'rebate-list',
        component: () => import(/* webpackChunkName: "rebate-list" */ '@/views/manage/rebate/index.vue'),
        meta: { title: '佣金列表', affix: true }
      },
      {
        path: '/article/list',
        name: 'article-list',
        component: () => import(/* webpackChunkName: "article-list" */ '@/views/manage/article/index.vue'),
        meta: { title: '文章管理', affix: true }
      },
      {
        path: '/member/list',
        name: 'member-list',
        component: () => import(/* webpackChunkName: "member-list" */ '@/views/manage/member/index.vue'),
        meta: { title: '会员管理', affix: true }
      },
      {
        path: '/member/group',
        name: 'member-group',
        component: () => import(/* webpackChunkName: "member-list" */ '@/views/manage/member/group/index.vue'),
        meta: { title: '会员分组', affix: true }
      },
      {
        path: '/member/rank',
        name: 'member-rank',
        component: () => import(/* webpackChunkName: "member-list" */ '@/views/manage/member/rank/index.vue'),
        meta: { title: '会员级别', affix: true }
      },
      {
        path: '/area/list',
        name: 'area-list',
        component: () => import(/* webpackChunkName: "member-list" */ '@/views/manage/area/index.vue'),
        meta: { title: '区域管理', affix: true }
      },
      {
        path: '/bank/list',
        name: 'bank-list',
        component: () => import(/* webpackChunkName: "product-list" */ '@/views/manage/bank/index.vue'),
        meta: { title: '供应商管理', affix: true }
      },
      {
        path: '/category/list',
        name: 'category-list',
        component: () => import(/* webpackChunkName: "category-list" */ '@/views/manage/category/index.vue'),
        meta: { title: '分类管理', affix: true }
      },
      {
        path: '/rebate_rule/list',
        name: 'rebate_rule-list',
        component: () => import(/* webpackChunkName: "rebate_rule-list" */ '@/views/manage/rebate_rule/index.vue'),
        meta: { title: '佣金规则', affix: true }
      },
      {
        path: '/withdraw/list',
        name: 'withdraw-list',
        component: () => import(/* webpackChunkName: "withdraw-list" */ '@/views/manage/withdraw/index.vue'),
        meta: { title: '提现管理', affix: true }
      },
      {
        path: '/guest/list',
        name: 'guest-list',
        component: () => import(/* webpackChunkName: "guest-list" */ '@/views/manage/guest_info/index.vue'),
        meta: { title: '客户管理', affix: true }
      },
      {
        path: '/industry/list',
        name: 'industry-list',
        component: () => import(/* webpackChunkName: "industry-list" */ '@/views/manage/industry/index.vue'),
        meta: { title: '行业管理', affix: true }
      },
      {
        path: '/item/list',
        name: 'item-list',
        component: () => import(/* webpackChunkName: "item-list" */ '@/views/manage/item/index.vue'),
        meta: { title: '代理商管理', affix: true }
      },
      {
        path: '/statistics/statistics',
        name: 'statistics-statistics',
        component: () => import(/* webpackChunkName: "item-list" */ '@/views/manage/statistics/statistics.vue'),
        meta: { title: '统计报表', affix: true }
      },
      {
        path: '/404',
        component: () => import('@/views/error-page/404'),
        hidden: true
      }
    ]
  }
];
