<template>
  <div>
    <div class="title" >表格设置</div>
    <!-- {{ form }} -->
    <div class="item">
      <span class="item-label" style="margin-right: 5px;">表格尺寸：</span>
      <avue-radio v-model="form['size']" :dic="[
        {
          label: 'small',
          value: 'small'
        },
        {
          label: 'medium',
          value: 'medium'
        },
        {
          label: 'mini',
          value: 'mini'
        }]"></avue-radio>
    </div>
    <div class="item">
      <span class="item-label" style="margin-right: 5px;">搜索尺寸：</span>
      <avue-radio v-model="form['searchSize']" :dic="[
        {
          label: 'small',
          value: 'small'
        },
        {
          label: 'medium',
          value: 'medium'
        },
        {
          label: 'mini',
          value: 'mini'
        }]"></avue-radio>
    </div>
    <div class="item" v-for="(item, index) in list" :key="index">
      <span class="item-label" style="margin-right: 5px;">{{item.label}}：</span>
      <avue-switch  v-model="form[item.prop]"></avue-switch>
    </div>
    <div>
       <el-button type="primary" @click="sbm">设置</el-button>
    </div>
  </div>
</template>

<script>
import './index.scss';
// import {
//   _getSessionStore
// } from '@/utils/storage';

export default {
  name: 'index',
  props: {
    urlList: {
      type: Array,
      default () {
        return [];
      }
    },
    iconColor: {
      type: String,
      default: 'royalblue'
    }
  },
  watch: {
    form: {
      handler (newV) {
        console.log('this.$config.table', this.$config.table);
        this.$store.dispatch('config/setTableConfig', newV);
        this.reload();
      },
      deep: true
    }
    // form(newV) {
    //   console.log(newV);
    //   _getSessionStore('TableConfig', 'JSONStr');
    //   _setSessionStore(
    //     'TableConfig',
    //     newV,
    //     'JSONStr'
    //   );
    //   console.log('this.$config.table', this.$config.table);
    // }
  },
  inject: ['reload'], // 注入reload方法
  data () {
    return {
      form: this.$store.getters.tableConfig,
      list: [
        {
          label: '卡片',
          prop: 'card',
          dic: []
        },
        {
          label: '边框',
          prop: 'border',
          dic: []
        },
        {
          label: '索引',
          prop: 'index',
          dic: []
        },
        {
          label: '筛选',
          prop: 'filterBtn',
          dic: []
        },
        {
          label: '斑马线',
          prop: 'stripe',
          dic: []
        },
        {
          label: 'Modal关闭',
          prop: 'dialogClickModal',
          dic: []
        },
        {
          label: '多选tag',
          prop: 'tip',
          dic: []
        }
      ]
    };
  },
  methods: {
    sbm () {
      // location.reload();
      // this.location.reload();
      // this.$router.go(0);
      this.reload();
    },
    chenge (item) {
      console.log(item);
      this.chengeData = item[this.name];
      this.$emit('chenge', item);
    }
  }
};
</script>

<style lang='scss' scoped></style>
