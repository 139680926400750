import axios from 'axios';
import { Message, Loading } from 'element-ui';
import _ from 'lodash';
import { bulidSign } from '@/utils/crypto';
import router from '@/router';
import store from '@/store';
import { getToken, removeToken } from '@/utils/auth';

// loading对象
let loading;
// 当前正在请求的数量
let needLoadingRequestCount = 0;
// 显示loading
const showLoading = target => {
  // 后面这个判断很重要，因为关闭时加了抖动，此时loading对象可能还存在，
  // 但needLoadingRequestCount已经变成0.避免这种情况下会重新创建个loading
  if (needLoadingRequestCount === 0 && !loading) {
    loading = Loading.service({
      lock: true,
      text: 'Loading...',
      background: 'rgba(255, 255, 255, 0.5)',
      target: target || 'body'
    });
  }
  needLoadingRequestCount += 1;
};
// 防抖：将 300ms 间隔内的关闭 loading 便合并为一次。防止连续请求时， loading闪烁的问题。
const toHideLoading = _.debounce(() => {
  loading.close();
  loading = null;
}, 800);

// 隐藏loading
function hideLoading () {
  needLoadingRequestCount -= 1;
  needLoadingRequestCount = Math.max(needLoadingRequestCount, 0); // 做个保护
  if (needLoadingRequestCount === 0) {
    // 关闭loading
    toHideLoading();
  }
}

const http = axios.create({
  baseURL: 'https://api.tangchengshuke.com/', // 'http://ow3.xzusoft.cn/',
  // baseURL: 'http://ow3.xzusoft.cn/',
  timeout: 10000
});
// 添加拦截器
http.interceptors.request.use(
  config => {
    // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
    // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
    let utoken = '';
    if (store.getters.token) {
      utoken = getToken();
    }
    if (config.headers.showLoading !== false) {
      showLoading();
    }
    const { data } = config;
    console.log(config);
    const timestamp = Date.parse(new Date()) / 1000;
    // TODO 请求数据统一加密
    const sign = bulidSign(utoken, timestamp, data);
    config.headers = {
      timestamp,
      sign,
      'X-Token': utoken,
      // 'Content-Type': 'application/x-www-form-urlencoded'
      'Content-Type': 'multipart/form-data'
    };
    // TODO 上传无需格式化
    if (config.url.split('/').pop() === 'upload') {
      return config;
    }
    // 对全局参数做过滤，把不存在的参数删除
    if (config.method === 'post') {
      for (const key in data) {
        if (!config.data[key] && config.data[key] !== 0) {
          delete config.data[key];
        }
      }
    } else if (config.method === 'get') {
      for (const key in config.data) {
        console.log(key, config.data);
        if (!config.params[key] && config.params[key] !== 0) {
          delete config.params[key];
        }
      }
    }
    // 全局去前后空格;
    // function dataTrim(data) {
    //   if (Array.isArray(data)) {
    //     for (let item of data) {
    //       if (typeof item === 'object') {
    //         dataTrim(item);
    //       } else if (typeof item === 'string') {
    //         item = item.trim();
    //       }
    //     }
    //   } else if (typeof data === 'object') {
    //     for (const key in data) {
    //       if (typeof data[key] === 'object') {
    //         dataTrim(data[key]);
    //       } else if (typeof data[key] === 'string') {
    //         data[key] = data[key].trim();
    //       }
    //     }
    //   }
    // }
    // dataTrim(config.data);
    const formData = new FormData();
    for (const key in data) {
      if (Array.isArray(config.data[key])) {
        formData.append(key, JSON.stringify(config.data[key]));
      } else {
        formData.append(key, config.data[key]);
      }
    }
    config.data = formData;
    return config;
  },
  error => {
    hideLoading();
    return Promise.reject(error);
  }
);

// 添加返回拦截器
http.interceptors.response.use(
  response => {
    // eslint-disable-nex/*  */t-line no-console
    hideLoading();
    // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据,否则的话抛出错误
    // arraybuffer
    if (response.status === 200) {
      // 异步文件下载
      if (response.config.responseType === 'blob') {
        const url = window.URL.createObjectURL(response.data);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'test.xlsx';
        a.click();
        return Promise.resolve(response.data);
      }
      const res = response.data;
      console.log('抛出接口异常:------', res);
      if (res.code !== 200) {
        Message({
          message: res.msg ? res.msg : '网络或系统服务错误!!!~',
          type: 'error',
          duration: 3 * 1000
        });
        console.error('抛出接口异常:------', response);
        // return Promise.reject(response);
        return Promise.reject(new Error(response.data || '异常'));
      }
      return Promise.resolve(response.data);
    }
    return Promise.reject(response);
  },
  error => {
    console.log(error);
    hideLoading();
    if (error.response.status) {
      switch (error.response.status) {
        // 401: 未登录
        // 未登录则跳转登录页面，并携带当前页面的路径
        // 在登录成功后返回当前页面，这一步需要在登录页操作。
        case 401:
          // // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
          removeToken();
          Message({
            message: '未登录',
            type: 'error',
            duration: 3 * 1000
          });
          setTimeout(() => {
            router.replace({
              path: '/login'
            });
          }, 1000);
          break;
        // 403 token过期
        // 登录过期对用户进行提示
        // 清除本地token和清空vuex中token对象
        // 跳转登录页面
        case 403:
          removeToken();
          Message({
            message: '登录过期',
            type: 'error',
            duration: 3 * 1000
          });
          console.log('登录过期-----------------------------', router.currentRoute.fullPath);
          router.replace({
            path: '/login',
            query: {
              redirect: router.currentRoute.fullPath
            }
          });
          // setTimeout(() => {
          // }, 1000);
          break;
        // 404请求不存在
        case 404:
          // console.log(error.response.msg)
          Message({
            message: error.response.data.msg
              ? error.response.data.msg
              : 'ERROR:未找到符合要求的资源或服务~',
            type: 'error',
            duration: 1500
          });
          break;
        case 502:
          Message({
            message: error.response.data.msg ? error.response.data.msg : 'ERROR:网络错误,~',
            type: 'error',
            duration: 1500
          });
          break;
        // 其他错误，直接抛出错误提示
        default:
          Message({
            message: error.response.data.msg || '网络或系统服务错误~',
            type: 'error',
            duration: 3 * 1000
          });
      }
      // console.error('抛出接口异常:', error.response);
      console.log('抛出接口异常------:', error);
      return Promise.reject(error.response);
    }
    return Promise.reject(error);
  }
);

export default http;
