<template>
  <div class="right-panel">
    <slot></slot>
  </div>
</template>

<script>
import './index.scss';

export default {
  name: 'index'
};
</script>
