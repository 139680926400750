/* eslint-disable quotes */
import http from "@/utils/http";

/**
 * 系统菜单
 */
export default {
  getList: {
    p: "post,/system/user/getlist",
    r: data => http({ url: "/system/user/getlist", method: "post", data })
  },
  add: {
    p: "post,/system/user/add",
    r: data => http({ url: "/system/user/add", method: "post", data })
  },
  update: {
    p: "post,/system/user/update",
    r: data => http({ url: "/system/user/update", method: "post", data })
  },
  del: {
    p: "post,/system/user/delete",
    r: data => http({ url: "/system/user/delete", method: "post", data })
  },
  updatePass: {
    p: "post,/system/user/update_password",
    r: data => http({ url: "/system/user/update_password", method: "post", data })
  },
  changePwd: {
    p: "post,/system/change/password",
    r: data => http({ url: "/system/change/password", method: "post", data })
  },
  getOne: {
    p: "post,/system/user/getOne",
    r: data => http({ url: "/system/user/getOne", method: "post", data })
  },
  exportFile: {
    p: "post,/system/export/user",
    r: data => http({ url: "/system/export/user", method: "post", responseType: "blob", data })
  }
};
